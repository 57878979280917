export const endpoints = {
    localization_language: "localization/language",
    localization_tax: "localization/tax",
    localization_country: "localization/country",
    localization_country_state: "localization/country/state",
    localization_country_city: "localization/country/city",
    admin: "admin",
    admin_group: "admin/group",
    admin_group_duplicate: "admin/group/duplicate",
    admin_group_endpoint: "admin/group/endpoint",
    admin_change_password: "admin/change-password",
    auth_change_password: "auth/change-password",
    ecommerce_product: "ecommerce/product",
    ecommerce_product_category: "ecommerce/product/category",
    ecommerce_product_operation: "ecommerce/product/operation",
    ecommerce_product_group: "ecommerce/product/group",
    ecommerce_product_package_item: "ecommerce/product/package/item",
    ecommerce_product_addon: "ecommerce/product/addon",
    ecommerce_product_trailer: "ecommerce/product/trailer",
    ecommerce_product_type: "ecommerce/product/type",
    ecommerce_product_variant: "ecommerce/product/variant/combination",
    ecommerce_product_variant_group: "ecommerce/product/variant/group",
    ecommerce_product_variant_item: "ecommerce/product/variant/item",
    ecommerce_product_index_raw: "ecommerce/product/index-raw",
    ecommerce_payment_method: "ecommerce/payment-method",
    ecommerce_payment_method_type: "ecommerce/payment-method/type",
    ecommerce_payment_method_sub_method: "ecommerce/payment-method/sub-method",
    ecommerce_order: "ecommerce/order",
    ecommerce_order_status: "ecommerce/order/status",
    ecommerce_order_export: "ecommerce/order/export",
    ecommerce_order_note: "ecommerce/order/note",
    ecommerce_order_payment_collection: "ecommerce/order/payment-collection",
    ecommerce_order_item_change_usage: "ecommerce/order/item/change-usage",
    ecommerce_order_item_change_usage_multi: "ecommerce/order/item/change-usage-multi",
    ecommerce_order_creator: "ecommerce/order/creator",
    ecommerce_order_creator_export: "ecommerce/order/creator/export",
    ecommerce_order_shipping: "ecommerce/order/shipping",
    ecommerce_order_shipping_waybill_preview: "ecommerce/order/shipping/waybill-preview",
    ecommerce_order_shipping_detail: "ecommerce/order/shipping/details",
    ecommerce_shipping_method: "ecommerce/shipping-method",
    ecommerce_shipping_method_rate: "ecommerce/shipping-method/rate",
    ecommerce_discount_coupon: "ecommerce/discount-coupon",
    ecommerce_discount_coupon_export: "ecommerce/discount-coupon/export/excel",
    ecommerce_discount_coupon_generate_code: "ecommerce/discount-coupon/generate-code",
    ecommerce_discount_coupon_creator: "ecommerce/discount-coupon/creator",
    ecommerce_invoice: "ecommerce/invoice",
    ecommerce_invoice_preview: "ecommerce/invoice/preview",
    ecommerce_invoice_show_legalized_pdf: "ecommerce/invoice/show-legalized-pdf",
    ecommerce_invoice_provider: "ecommerce/invoice/provider",
    ecommerce_invoice_provider_payment_method: "ecommerce/invoice/provider/payment-method",
    ecommerce_invoice_provider_util_stock_list: "ecommerce/invoice/provider/utils/stock-list",
    ecommerce_invoice_provider_util_product_list: "ecommerce/invoice/provider/utils/product-list",
    ecommerce_invoice_provider_util_is_support: "ecommerce/invoice/provider/utils/is-support",
    ecommerce_invoice_provider_product: "ecommerce/invoice/provider/product",
    ecommerce_invoice_provider_product_product_structure: "ecommerce/invoice/provider/product/product-structure",
    ecommerce_invoice_provider_product_create_product: "ecommerce/invoice/provider/product/create-product",
    user: "user",
    user_gender: "user/gender",
    user_address: "user/address",
    user_change_password: "user/change-password",
    user_self_login: "user/self-login",
    user_export: "user/export",
    user_creator: "user/creator",
    user_creator_export: "user/creator/export",
    user_check: "user/check/plain",
    user_history_login: "history/login",
    user_label: "user/label",
    user_label_creator: "user/label/creator",
    user_label_creator_export: "user/label/creator/export",
    module: "module",
    module_lesson_type: "module/lesson-type",
    module_exam_date: "module/exam-date",
    module_teacher: "module/teacher",
    module_teacher_branch: "module/teacher/branch",
    module_teacher_trailer: "module/teacher/trailer",
    module_lecture: "module/lecture",
    module_lecture_multi_sort: "module/lecture/multi-sort",
    module_lecture_patch: "module/lecture/patch",
    module_lecture_stream: "module/lecture/stream",
    module_lecture_directory: "module/lecture/directory",
    module_lecture_directory_multi_sort: "module/lecture/directory/multi-sort",
    module_lecture_directory_patch: "module/lecture/directory/patch",
    module_lecture_directory_mode: "module/lecture/smart",
    module_document: "module/document",
    module_branch_course: "module/branch-course",
    module_branch_course_branch: "module/branch-course/branch",
    module_branch_course_reservation: "module/branch-course/reservation",
    module_branch_course_reservation_export: "module/branch-course/reservation/export",
    module_branch_course_reservation_block: "module/branch-course/reservation/block",
    module_adobe_connect_group: "module/adobe-connect/group",
    module_adobe_connect_setting: "module/adobe-connect/setting",
    module_adobe_connect_old_group: "module/adobe-connect-old/group",
    module_adobe_connect_old_setting: "module/adobe-connect-old/setting",
    module_out_question: "module/out-question",
    module_out_question_category: "module/out-question/category",
    module_blog: "module/blog",
    module_blog_category: "module/blog/category",
    module_popup: "module/popup",
    module_popup_button: "module/popup/button",
    module_slider_group: "module/slider/group",
    module_slider_item: "module/slider/item",
    module_slider_item_button: "module/slider/item/button",
    module_exam: "module/exam",
    module_exam_result: "module/exam/result",
    module_exam_clone_question: "module/exam/clone/question",
    module_exam_category: "module/exam/category",
    module_exam_template_type: "module/exam/template/type",
    module_exam_session: "module/exam/session",
    module_exam_session_user: "module/exam/session/user",
    module_exam_session_user_finish: "module/exam/session/user/finish",
    module_exam_question: "module/exam/question",
    module_exam_question_category: "module/exam/question/category",
    module_exam_question_type: "module/exam/question/type",
    module_exam_question_answer: "module/exam/question/answer",
    module_exam_question_solution: "module/exam/question/solution",
    module_exam_question_relation: "module/exam/question/relation",
    module_exam_gain: "module/exam/gain",
    module_voice_recording: "module/voice-recording",
    module_voice_recording_category: "module/voice-recording/category",
    module_paragraph: "module/paragraph",
    module_paragraph_sentence: "module/paragraph/sentence",
    module_paragraph_sentence_multi_sort: "module/paragraph/sentence/multi-sort",
    module_paragraph_sentence_multi_update: "module/paragraph/sentence/multi-update",
    module_paragraph_level: "module/paragraph/levels",
    module_paragraph_question: "module/paragraph/question",
    module_paragraph_question_type: "module/paragraph/question/type",
    module_paragraph_question_answer: "module/paragraph/question/answer",
    module_zoom_host: "module/zoom/host",
    module_zoom_meeting: "module/zoom/meeting",
    module_zoom_meeting_type: "module/zoom/meeting/type",
    module_zoom_meeting_registrant: "module/zoom/meeting/registrant",
    module_zoom_meeting_generate_code: "module/zoom/meeting/generate-code",
    module_zoom_meeting_recording: "module/zoom/meeting/recording",
    module_tracking: "module/tracking-code",
    module_tracking_location: "module/tracking-code/location",
    module_lesson_calendar: "module/lesson-calendar",
    module_lesson_calendar_batch: "module/lesson-calendar/batch",
    module_source: "module/sources",
    module_source_group: "module/sources/group",
    module_source_category: "module/sources/category",
    module_featured_product: "module/featured-product",
    module_featured_product_group: "module/featured-product/group",
    module_push_notification: "module/push/notification",
    module_push_status: "module/push/status",
    module_push_provider: "module/push/provider",
    module_push_provider_type: "module/push/provider/type",
    module_teacher_book_request: "module/teacher-book-request",
    module_teacher_book_request_export: "module/teacher-book-request/export/excel",
    module_yks_lgs_hazirlik_destegi: "module/yks-lgs-hazirlik-destegi",
    module_yks_lgs_hazirlik_destegi_export: "module/yks-lgs-hazirlik-destegi/export/excel",
    module_question_solution_category: "module/question-solution/category",
    module_question_solution_category_tree: "module/question-solution/category/tree",
    module_question_solution_book: "module/question-solution/book",
    module_question_solution_book_chapter: "module/question-solution/book/chapter",
    module_question_solution_book_chapter_tree: "module/question-solution/book/chapter/tree",
    module_question_solution_book_chapter_question: "module/question-solution/book/chapter/question",
    module_form: "module/form",
    module_form_element: "module/form/element",
    module_form_element_type: "module/form/element/type",
    module_form_answer: "module/form/answer",
    module_form_answer_export: "module/form/answer/export/excel",
    system_variable: "system/variable",
    system_setting: "system/setting",
    system_setting_register_element: "system/setting/register-element",
    system_setting_register_element_type: "system/setting/register-element/type",
    site_setting: "site/setting",
    file: "file",
    theme: "theme",
    theme_customize: "theme/customize",
    link_type: "link/type",
    link_type_assigned: "link/type/assigned",
    link_manager: "link/manager",
    page: "page",
    statistic_general: "statistic/general",
    statistic_student: "statistic/student",
    statistic_product: "statistic/product",
    statistic_product_export: "statistic/product/export/excel",
    statistic_product_specific: "statistic/product/specific",
    statistic_lesson: "statistic/lesson",
    statistic_lesson_export: "statistic/lesson/export/excel",
    sms_provider: "sms/provider",
    sms_multi_send: "sms/multi-send",
    sms_multi_send_export: "sms/multi-send/export",
    sms_check_phone: "sms/check/phone",
    notification_item: "notification/item",
    notification_item_group: "notification/item/group",
    notification_item_sub_group: "notification/item/sub-group",
    notification_item_template: "notification/item/template",
    stream_provider: "stream/provider",
    stream_provider_account: "stream/provider/account"
}
